import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Flex, Box } from "@rebass/grid"
import styled from "styled-components"
import cookie from "services/CookieStorage"
import DropdownMenu from "dumbComponents/common/UI/DropdownMenu"
const user = cookie.load("user")
const StyledBox = styled.div`
  z-index: 2999;
  box-shadow: 0 0.5px 2.5px 0 rgba(0, 0, 0, 0.25);
  &:before {
    box-sizing: border-box;
  }
`

const ContainerBox = styled(Box)`
  height: 70px;
`

const Brand = styled.div`
  float: left;
  padding: 2;
`

const NavCollapse = styled.div`
  border-top: none;
  padding-right: 0;
  background: #ffffff;
  padding-left: 15px;
  position: relative;
  display: block;
  padding-bottom: 0;
  overflow: visible;
  &::before {
    box-sizing: border-box;
  }
  @media (max-width: 500px) {
    display: none;
  }
`

const NavLeft = styled.ul`
  height: auto;
  position: relative;
  margin: 0px 15px 0 0;
  cursor: pointer;
  float: left;
  list-style: none;
  padding-left: 5px;
  display: inline-flex;
  &:before {
    box-sizing: border-box;
  }
`

const NavRight = styled(NavLeft)`
  float: right;
`

const NavItem = styled.li`
  display: block;
`

const NavAnchor = styled.a`
  border-radius: 0px;
  color: #354052;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19px;
  opacity: 0.9;
  padding: 0 12px 0 12px;
  position: relative;
  text-align: center;
  height: 66px;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    background: #fff;
    cursor: pointer;
    color: #11adf3;
    border-bottom: 4px solid #11adf3;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  &:before {
    box-sizing: border-box;
  }
  &:after {
    box-sizing: border-box;
  }
  img {
    margin: 0 auto 3px;
    display: block;
    max-width: none;
    vertical-align: middle;
    border: 0;
    padding-top: 5px;
  }
`

const DropdownAnchor = styled.a`
  text-decoration: none;
  color: #354052;
  &:hover {
    color: #2684ff;
  }
`

class Navbar extends Component {
  state = {
    showLogin: false,
  }

  goToPage = (type) => {
    if (!user) {
      if (type === "editprofile") {
        cookie.save("redirectUrl", `${RADIUS_URL}/page/redirect?tag=profile_page&params={}`)
      } else {
        cookie.save("redirectUrl", `${RADIUS_URL}/page/redirect?tag=outbound_referrals&params={}`)
      }
      window.location.href = `${RADIUS_URL}/association/womens-council/start`
    } else if (type === "editprofile") {
      window.location.href = user.profile_url || `${PROFILE_URL}profile/agent?id=${user.id}`
    } else if (type === "referrals") {
      window.location.href = `${APP_URL}/referrals`
    }
  }

  logout = () => {
    cookie.remove("user")
    cookie.remove("secureId")
    cookie.remove("leadAgreementSigned")
    this.setState({ showLogin: true })
  }

  render() {
    const { showLogin } = this.state
    return (
      <StyledBox>
        <Flex alignItems="center">
          <ContainerBox className="container">
            <Brand>
              <Link to="/">
                <img
                  src="https://s3.amazonaws.com/cdn.agentdesks.com/images/wcr_logo.jpg"
                  height={65}
                  alt="Women's council of realtors"
                />
              </Link>
            </Brand>
            <NavCollapse>
              <NavRight>
                <NavItem>
                  <DropdownMenu
                    overlay={(
                      <NavAnchor>
                        <span>{user && !showLogin ? "My Account" : "Login"}</span>
                      </NavAnchor>
                    )}
                  >
                    <li>
                      <DropdownAnchor onClick={() => this.goToPage("editprofile")}>Edit my profile</DropdownAnchor>
                    </li>
                    <li>
                      <DropdownAnchor onClick={() => this.goToPage("referrals")}>
                        Send and receive referrals
                      </DropdownAnchor>
                    </li>
                    {user
                      && !showLogin && (
                      <li>
                        <DropdownAnchor onClick={() => this.logout()}>Logout</DropdownAnchor>
                      </li>
                    )}
                  </DropdownMenu>
                </NavItem>
              </NavRight>
            </NavCollapse>
          </ContainerBox>
        </Flex>
      </StyledBox>
    )
  }
}

export default Navbar
